@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

.image img {
  height: 55px;
  width: 55px;
  border-radius: 50px;
  box-shadow: inset 0 0 1px #e0e0e0, inset 0 0 14px rgba(0, 0, 0, 0.2);
}

.control-section {
  margin-top: 100px;
}

ejs-grid .e-column {
  padding: 0 8px;
}

/* Align text to the header text */
ejs-grid .e-column .e-headercell,
ejs-grid .e-column .e-headercelldiv {
  text-align: center;
}

.card {
  margin-top: 1rem;
  padding: 1.5rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.col-md-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-md-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.font-weight-bolder {
  font-weight: 400;
}

.d-flex {
  display: flex;
}

.flex-fill {
  flex: 1;
}

.pb-0 {
  padding-bottom: 0;
}

.btn {
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  height: 50px;
  padding: 0.5rem 1rem;
}

.bg-green {
  background-color: #28a745;
  color: #fff;
}

.text-white {
  color: #fff;
}

.text-center {
  text-align: center;
}

.btn-warning {
  background-color: #ffc107;
  color: #000;
}

.btn-block {
  display: block;
  width: 100%;
}

.p-5 {
  padding: 1.25rem;
}

.w-100 {
  width: 100%;
}

.mat-spinner {
  margin: 1rem auto;
}

.text {
  text-align: center;
  font-size: 19px;
  font-weight: 700;
  line-height: 30.6px;
  color: #441E62;
}

/* Apply the desired style to all header cells */
.e-grid .e-headercell {
  color: #190429 !important;
  /* Add any other styling you want for the header text */
}

/* card container */
.card {
  margin-top: 2rem;
  padding: 1.5rem;
}

/* Search section */
.search-section {
  display: flex;
  flex-direction: column;
}

.search-section .mat-form-field {
  width: 100%;
}

/* OR section */
.or-section {
  text-align: center;
  margin-top: 1.5rem;
}

/* Header text color */
.header-text {
  color: #441e62;
}

.e-grid .e-pager .e-pager-item.e-active {
  background-color: #441e62;
  border-color: #441e62;
  color: white;
}

.grid-container {
  position: relative;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Poppins, "Helvetica Neue", sans-serif;
}

.custom-button {
  background-color: #441E62;
}

button {
  box-shadow: none !important;
  outline: none !important;
}

/* create buttom */
button.custom-button.mdc-button.mdc-button--unelevated.mat-mdc-unelevated-button.mat-unthemed.mat-mdc-button-base {
  background: #441E62;
  width: 173px;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  line-height: 19.5px;
  text-align: left;

}

.wrapper {
  padding: 20px;
  background-color: #E0E2E4;
}

.first-header {
  font-size: 28px;
  font-weight: 700;
  line-height: 33.6px;
  text-align: left;
  color: #441E62 !important;
}

.investment-plan {
  margin-top: 20px
}

.upload {
  margin-top: 4%;
  margin-bottom: 4%;
}

.icon-with-text {
  display: flex;
  align-items: center;
}

.page-container {
  height: 100vh;
  background-color: #f3f4f5 !important;
  padding: 20px;
}

.page-container-edit {
  height: 100vh;
  padding: 20px;
}

.sub-container-box {
  margin-top: 30px;
  background: #f3f4f5;
  padding: 1%;
}

.mat-mdc-tab-list {
  margin-top: 10px;
  height: 36px;
}
.mat-mdc-tab:not(.mdc-tab--stacked) {
  height: 36px !important;
}
.e-grid .e-headercell {
  background-color: #f3f4f5;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 14.4px !important;
  text-align: left;
  color: #441E62 !important;
}

.e-grid .e-headertext {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  text-align: left;
  color: #393E43 !important;
  font-family: Inter
}

.e-grid td.e-rowcell {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  text-align: left;
  color: #441E62 !important;
  border: 1px solid #F3F4F5;
  font-family: Inter
}

/* table sort icons*/
.e-grid .e-headercell .e-sortfilterdiv:before {
  content: '';
}

.e-grid .e-headercell .e-ascending::after {
  content: '\f077';
  font-family: 'FontAwesome';
}

.e-grid .e-headercell .e-descending::after {
  content: '\f078';
  font-family: 'FontAwesome';
}

.e-grid {
  border-color: #e0e0e0;
  border-radius: 3px !important;
}

.normal-header {
  font-size: 16px;
  font-weight: 600;
  line-height: 20.8px;
  text-align: left;
  color: #441E62;
  font-family: Poppins
}

.paragraph-normal {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  text-align: left;
  color: #441E62;
  font-family: Inter
}

.paragraph-bold {
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.4px;
  text-align: left;
  color: #441E62;
}

.paragraph-typo {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  text-align: left;
  color: #353F4A;
}

.paragraph-medium {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  text-align: left;
  color: #441E62;
  font-family: Poppins
}

.regular-paragraph-medium {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.paragraph-large {
  color: #441E62;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
}

.contact-details-headers {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
  text-align: left;
  color: #441E62;
  margin-top: 5%;
}

.contact-header {
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 29.05px;
  text-align: left;
  color: #441E62;
  margin-bottom: 7%;
}
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
  background: #ced4da !important;
}
.yellow-button {
  background-color: #E39F09;
  border: 2px solid #E39F09;
  color: #441E62;
  border-radius: 6px;
  font-family: Poppins;
  font-weight: 500;
  gap: 4px;
  height: 40px;
  max-width: 300px;
  min-width: 100px;
  opacity: 0px;
  padding: 8px 16px;
}

.yellow-button-disabled {
  background-color: #bdbdbd !important;
  border: 2px solid #bdbdbd !important;
  border-radius: 6px;
  font-family: Poppins;
  font-weight: 500;
  gap: 4px;
  height: 40px;
  max-width: 300px;
  min-width: 100px;
  opacity: 0px;
  padding: 8px 16px;
}

.blue-line-button {
  max-width: 300px;
  min-width: 100px;
  height: 40px;
  padding: 8px 16px 8px 16px;
  gap: 4px;
  opacity: 0px;
  color: #441E62;
  border: 2px solid #441E62 !important;
  background-color: #e0e2e400;
  border-radius: 6px;
  font-family: Poppins;
  font-weight: 500;
}

.blue-button {
  max-width: 300px;
  min-width: 100px;
  height: 40px;
  padding: 8px 16px 8px 16px;
  gap: 4px;
  opacity: 0px;
  background-color: #441E62;
  border-radius: 6px;
  border: none;
  color: white;
  font-family: Poppins;
  font-weight: 500;
}

.blue-button-disabled {    
  background-color: #bdbdbd !important;
  border: 2px solid #bdbdbd !important;
  border-radius: 6px;  
  color: white;
  font-family: Poppins;
  font-weight: 500;
  gap: 4px;
  height: 40px;
  max-width: 300px;
  min-width: 100px;
  opacity: 0px;
  padding: 8px 16px;   
}

.outline-btn {
  border: 2px solid #441E62;
  color: #441E62; 
  background-color: #fff;
  padding: 8px 16px;
  border-radius: 6px;
  font-family: Poppins;
  font-weight: 500;
  height: 40px;
  text-align: center;  
}

.white-button-border-blue {
  width: 100px;
  height: 35px;
  border-radius: 6px;
  border: 2px solid #441E62;
  background-color: #e0e2e400;
  color: #441E62;
  font-size: 13px;
  font-weight: 600;
  line-height: 19.5px;
  font-family: Poppins
}

.white-button-border-blue-disabled{
  border: 2px solid #e1e2e4;
  cursor: not-allowed;  
  width: 100px;
  height: 35px;
  border-radius: 6px;
  color: #441E62;
  font-size: 13px;
  font-weight: 600;
  line-height: 19.5px;
  font-family: Poppins
}

.filter-text {
  display: flex;
  margin-left: 22px;
  gap: 20px;
}

textarea {
  font-size: 14px !important;
}

.mat-select-panel {
  background-color: white !important;
  /* Set dropdown background to white */
}

.mat-option-text {
  color: white !important;
  /* Set dropdown text color to white */
}

.mat-select-value-text {
  color: white !important;
  /* Set selected text color to white */
}

.mat-form-field-label {
  color: white !important;
  /* Set mat-label text color to white */
}

.mat-select-arrow {
  color: white !important;
  /* Set the select arrow color to white */
}

.e-pager .e-currentitem,
.e-pager .e-currentitem:hover {
  background: #bdbdbd !important;
  color: #fff;
  opacity: 1;
}

.e-checkbox-wrapper:hover .e-frame.e-check,
.e-css.e-checkbox-wrapper:hover .e-frame.e-check {
  background: #bdbdbd !important;
  border-color: rgba(0, 0, 0, 0);
  color: #fff;
}

.e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
  background: #bdbdbd !important;
  border-color: rgba(0, 0, 0, 0);
  color: #fff;
}
.e-switch-wrapper .e-switch-handle.e-switch-active, .e-css.e-switch-wrapper .e-switch-handle.e-switch-active {
  background-color: #fff !important;
}
.e-dropdownlist .e-ddl .e-list-item.e-active {
  background: #bdbdbd !important;
  color: white;
  /* Optional: Change the text color to white for better contrast */
}
.e-switch-wrapper .e-switch-on, .e-css.e-switch-wrapper .e-switch-on {
  background-color: #441e62 !important;
  color: #fff;
}

.create-btn {
  background-color: #E39F09;
  border: none;
  border-radius: 5px;
  color: #441E62;
  cursor: pointer;
  height: 40px;
  font-family: 'Poppins';
  font-size: 13px;
  font-weight: 600;
  line-height: 19.5px;
  padding: 8px 16px 8px 16px;
}

.mat-mdc-tab-header {
  z-index: 0 !important;
}
.e-accordion .e-acrdn-item .e-acrdn-header:focus {
  box-shadow: none !important;
}

.e-accordion .e-acrdn-item.e-selected.e-select.e-expand-state > .e-acrdn-header:focus,
.e-accordion .e-acrdn-item.e-selected.e-select,
.e-accordion .e-acrdn-item.e-select {
  border: none !important;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: transparent !important;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: white;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: transparent !important;
  --mat-tab-header-active-ripple-color: transparent !important;
  --mat-tab-header-inactive-ripple-color: transparent !important;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: transparent !important;
  --mat-tab-header-active-hover-label-text-color: transparent !important;
  --mat-tab-header-active-focus-indicator-color: transparent !important;
  --mat-tab-header-active-hover-indicator-color: transparent !important;
}

.mat-mdc-tab.mdc-tab:hover {
  background-color: rgb(68, 30, 98, 0.1) !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  font-weight: 900 !important;
  font-size: 14px;
}

.custom-dropdown {
  width: 150px; 
  border-radius: 4px;
  color: #441e62;
  border: none;
}

.description-textbox {
  height: 140px;
}

.fa-chevron-right:before {
  background: #441E62;
  color: #FEFEFE;
  border-radius: 50%;
  content: "\f054";
  padding: 7px 10px;
}

.e-outline.e-input-group:not(.e-input-focus), .e-outline.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):not(.e-input-focus), .e-outline.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):not(.e-input-focus), .e-outline.e-input-group.e-float-icon-left:not(.e-input-focus), .e-outline.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-input-focus), .e-outline.e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-outline.e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) {
  border-color: rgb(206 212 218);
  height: 40px;
}


.e-outline-address.e-input-group:not(.e-input-focus), .e-outline-address.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):not(.e-input-focus), .e-outline-address.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):not(.e-input-focus), .e-outline-address.e-input-group.e-float-icon-left:not(.e-input-focus), .e-outline-address.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-input-focus), .e-outline-address.e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-outline-address.e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) {
  border-color: rgb(206 212 218) !important;
  border: 1px solid rgb(206 212 218);
  border-radius: 5px;
  min-height: 40px;
}
.e-outline-address.e-input-group:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-outline-address.e-input-group.e-control-wrapper:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-outline-address.e-float-input.e-control-wrapper:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-outline-address.e-input-group.e-float-icon-left:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-outline-address.e-input-group.e-control-wrapper.e-float-icon-left:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-outline-address.e-float-input.e-float-icon-left:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-outline-address.e-float-input.e-control-wrapper.e-float-icon-left:not(.e-input-focus):hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-outline-address.e-float-input:hover:not(.e-input-focus):not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) {
  border-color: rgba(28, 27, 31) !important;
}


.e-outline.e-input-group.e-control-wrapper .e-input-group-icon {
  margin: 0px 12px 0px 0px !important;
}
.e-date-wrapper[_ngcontent-ng-c123093082]:not(.e-filled) .e-date-icon.e-icons[_ngcontent-ng-c123093082] {
  margin: 9px 12px 0px 0px !important;
}

span.e-input-group.e-control-wrapper.e-outline.custom-textbox.e-ddl.e-input-focus {
  height: 46px !important;
}
.disabled-div {
  pointer-events: none; 
  opacity: 0.5;
}

.disable-Supplier {
  pointer-events: none; 
  opacity: 0.9;
}
.disable-user {
  pointer-events: none; 
  opacity: 0.9;
}
.disableIFAwaitSignOffOrSigned{
  pointer-events: none; 
}
.e-ss-atc, .e-control.e-tab.e-lib.e-keyboard {
  display: none !important;
}
.htmlContent-hidden {
  position: absolute;
  left: -9999px; /* Move it off-screen */
  top: -9999px;
  width: 100%;  
}

